<template>
  <div class="user-manage">
    <table-search :total="total" :conditions="conditions" @searchRes="searchRes" @refresh="getList">
    </table-search>
    <div class="table">
      <el-table
        :data="tableData"
        border
        @sort-change="sortChange"
        style="width: 100%">
        <el-table-column
          prop="submitterAccountId"
          sortable="custom"
          label="用户ID">
        </el-table-column>
        <el-table-column
          prop="caseSubmitId"
          label="投标ID">
        </el-table-column>
        <el-table-column
          prop="submitterName"
          label="投标主体姓名">
        </el-table-column>
        <el-table-column
          prop="caseId"
          label="招标ID">
        </el-table-column>
        <el-table-column
          prop="publisherName"
          label="招标主体姓名">
        </el-table-column>
        <el-table-column
          prop="caseMainType"
          :filters="applyFilter"
          :filter-method="filterHandler"
          label="标的类型">
          <template slot-scope="scope">
            <span style="color:#409EFF;" v-if="scope.row.caseMainType==1">诉讼业务</span>
            <span style="color:#67C23A;" v-else-if="scope.row.caseMainType==2">非诉讼业务</span>
            <div>{{scope.row.caseTypeName}}</div>
          </template>
        </el-table-column>
        <el-table-column
          width="230"
          prop="id"
          label="时间信息">
          <template slot-scope="scope">
            <div>发布时间：{{scope.row.publishTime}}</div>
            <div>投标截止：{{scope.row.deadline}}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="caseSubmitStatus"
          :filters="typeFilter"
          :filter-method="filterHandler"
          label="投标状态">
          <template slot-scope="scope">
            <span :style="{'color':COLOR.red}"  v-show="scope.row.caseSubmitStatus==1">已中标</span>
            <span :style="{'color':COLOR.green}"  v-show="scope.row.caseSubmitStatus==0">未中标</span>
            <!-- <span :style="{'color':COLOR.orange}" v-show="scope.row.caseSubmitStatus==1">等待选标</span> -->
          </template>
        </el-table-column>  
        <el-table-column
          prop="caseStatus"
          label="招标状态">
          <template slot-scope="scope">
            <span>{{targetStatus[scope.row.caseStatus]}}</span>
          </template>
        </el-table-column>      
        <el-table-column
          width="160"
          prop="val"
          label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="checkHandle(scope.row)">招标详情</el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button type="text" @click="userHandle(scope.row)">{{scope.row.businessType==0?'投标详情':'报名详情'}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer flex-row">
      <div class="page">
        <el-pagination
          background
          :page-size="form.perPage"
          :current-page="form.page"
          @size-change="handleSizeChange"
          layout="prev, pager, next,sizes,jumper"
          :page-sizes="[10, 20, 30, 40]"
          @current-change="currentChange"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <submit-case-detail :info="submitInfo" ref="submitCaseDetail"></submit-case-detail>
    <bidding-release-dialog ref="biddingReleaseDialog" :typeFlag="false" :info='caseInfo'></bidding-release-dialog>
  </div>
</template>

<script>
import TableSearch from '../../components/table-search.vue'
import SubmitCaseDetail from './coms/submit-case-detail.vue'
import BiddingReleaseDialog from '../verify-manage/coms/bidding-release-dialog.vue'
import {caseSubmitList,caseSubmitDetail,caseDetail} from '../../api/verify'
import {mapState} from 'vuex'
export default {
  name:'signup',
  data () {
    return {
      submitInfo:{},//投标 报名详情
      caseInfo:{},//招标详情
      total:0,
      form:{
        page:1,
        perPage:10,
        sortWay:'',
        businessType:1
      },
      conditions:[
        {value:'accountId',label:'用户ID'},
        {value:'caseSubmitId',label:'投标ID'},
        {value:'submitterName',label:'投标主体姓名'},
        {value:'caseId',label:'招标ID'},
        {value:'publisherName',label:'招标主体姓名'},
      ],
      tableData:[
        
      ],
      multipleSelection:[],
        statusFilter:[
          {text: '正常', value: '1'}, 
          {text: '禁言', value: '2'}, 
          {text: '禁止登陆', value: '3'}
        ],
        applyFilter:[
          {text: '诉讼业务', value: 1}, 
          {text: '非诉讼业务', value: 2}
        ],
        typeFilter:[
            // {text: '等待选标中', value: 2},
            {text: '已中标', value: 1}, 
            {text: '未中标', value: 0}
        ],

    };
  },
  components: {
    TableSearch,
    SubmitCaseDetail,
    BiddingReleaseDialog
  },
  computed: {
    ...mapState({
      targetStatus: state => state.dictionary.targetStatus
    }),
  },
  methods: {
    sortChange(sort){
      if(sort.prop =='submitterAccountId'){
        this.form.sortWay = sort.order==='ascending'? 1:2;
      }
      this.getList();
    },
    getList(){
      caseSubmitList(this.form).then(res=>{
        let data =res.data;
        if(data.code ==='000'){
          this.tableData = data.content.list;
          this.total = data.content.total;
        }else{
          console.log(data.desc)
        }
      })
    },
    searchRes(para){
      this.form = Object.assign({page:1,perPage:this.form.perPage},para);
      this.getList();
    },
    // 分页
    currentChange(val){
       this.form.page = val;
      this.getList();
    },
    handleSizeChange(val){
      this.form.perPage = val;
      this.form.page = 1;
      this.getList();
    },
    addHandle(){},
    checkHandle(row){
      caseDetail({caseId:row.caseId}).then(res=>{
        let data =res.data;
        if(data.code ==='000'){
          this.caseInfo = data.content;
        }else{
          console.log(data.desc)
        }
      })
      this.$refs.biddingReleaseDialog.dialogVisible = true;
    },
    userHandle(row){
      caseSubmitDetail({caseSubmitId:row.caseSubmitId}).then(res=>{
        let data =res.data;
        if(data.code ==='000'){
          this.submitInfo = data.content;
        }else{
          console.log(data.desc)
        }
      })
      this.$refs.submitCaseDetail.dialogVisible = true;
    },
    editHandle(row){},
    forbidAll(){},
    forbid(){},
    filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    }
  },
  created(){
    this.getList();
  }
}

</script>
<style lang='scss' scoped>
.footer{
  justify-content: flex-end;
  margin:15px 0;
}
.dot{
  font-size: 20px;
  padding-right: 5px;
  vertical-align:middle;
}
</style>