import { render, staticRenderFns } from "./submit-case-detail.vue?vue&type=template&id=583498b0&scoped=true&lang=html&"
import script from "./submit-case-detail.vue?vue&type=script&lang=js&"
export * from "./submit-case-detail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "583498b0",
  null
  
)

export default component.exports