<template lang="html">
<el-dialog
  title="投标详情"
  :visible.sync="dialogVisible"
  width="50%">
  <div>
    <el-descriptions title="">
        <el-descriptions-item label="招标ID">
          {{info.caseId}}
        </el-descriptions-item>
        <el-descriptions-item label="招标状态" >{{targetStatus[info.caseStatus]}}</el-descriptions-item>
        <el-descriptions-item label="投标ID">{{info.caseSubmitId}}</el-descriptions-item>
        <el-descriptions-item label="投标时间"> {{info.submitTime}}  </el-descriptions-item>
        <el-descriptions-item label="投标人ID"> {{info.submitterAccountId}}</el-descriptions-item>
        <el-descriptions-item label="投标人">{{info.submitterName}}</el-descriptions-item>
        <el-descriptions-item label="联系手机">{{info.contactPhoneNumber}}</el-descriptions-item>
        <el-descriptions-item label="资格解锁金">{{info.releaseAmount}}</el-descriptions-item>
        <el-descriptions-item label="解锁金订单号">{{info.releaseOrderNo}}</el-descriptions-item>
    </el-descriptions>
    <el-descriptions title="">
      <el-descriptions-item label="投标自述" :span='3'>{{info.description}}</el-descriptions-item>
      </el-descriptions>


    <el-descriptions title="相关文件" :colon="false">
      <el-descriptions-item :span="3" :labelStyle="{marginRight:0}" :key="index" v-for="(item,index) in info.caseFileList">
				<a :href="item.fileUrl" target="_blank" download style="color:#409EFF">{{item.fileName}}</a>
			</el-descriptions-item>
    </el-descriptions>
    <div v-if="!info.caseFileList || info.caseFileList.length==0">暂无</div>

  </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="cancel">关 闭</el-button>
  </span>
</el-dialog>
</template>

<script>
import {mapState} from 'vuex'
    export default {
      data(){
        return{
          dialogVisible:false,
          form:{
            verifyResult:'2',
            comments:''
          }
        }
      },
      props:{
        info:{
          default:function(){
            return {}
          },
          type:Object
        }
      },
      computed:{
        ...mapState({
          targetStatus : state => state.dictionary.targetStatus
        })
      },
      components:{},
      methods:{
        cancel(){
    			this.dialogVisible = false;
        }
      }
    }
</script>

<style scoped lang="scss">
</style>